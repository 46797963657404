<template>
    <v-container fluid class="fill-height text-center">
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                    :headers="headers"
                    :items="users"
                    :items-per-page="-1"
                    :search="searchUser"
                    :loading="loadingData"
                    item-key="id"
                    sort-by="id"
                    disable-sort
                    no-data-text="No hay datos para mostrar..."
                    loading-text="Cargando los datos, por favor espere."
                    class="elevation-1"
                    :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Gestión de Usuarios</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                    id="findtext-user"
                                    label="Buscar.."
                                    name="finduser"
                                    outlined
                                    dense
                                    single-line
                                    clearable
                                    background-color="white"
                                    hide-details
                                    v-model="searchUser"
                                    class="white--text mt-0 pt-0 mr-2"
                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-dialog
                                :fullscreen="$vuetify.breakpoint.smAndDown"
                                v-model="dialog"
                                max-width="600px"
                                persistent>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  :small="$vuetify.breakpoint.smAndDown"
                                        color="blue darken-1"
                                        dark
                                        depressed
                                        elevation="0"
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="newItem">
                                        Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-row class="mt-0" dense>
                                                <v-col cols="8">
                                                    <v-text-field
                                                            v-model="editedItem.name"
                                                            label="Nombre">
                                                    </v-text-field>
                                                    <v-text-field
                                                            v-model="editedItem.last_name"
                                                            label="Apellidos">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col class="pl-2 ml-2">
                                                    <v-row justify="center">
                                                        <v-img v-if="imagePreview != null"
                                                               :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                               :lazy-src="require('@/assets/images/no-image.jpg')"
                                                               max-height="100"
                                                               max-width="100"
                                                               aspect-ratio="1"
                                                               class="white--text align-center justify-center elevation-2">
                                                        </v-img>
                                                    </v-row>
                                                    <v-row>
                                                        <v-file-input
                                                                v-model="imageFile"
                                                                accept="image/png, image/jpeg, image/bmp"
                                                                show-size
                                                                clearable
                                                                label="Cargar/Cambiar imágen"
                                                                placeholder="Archivo de imágen"
                                                                prepend-icon="mdi-camera"
                                                                @change="changePreviewImage()">
                                                        </v-file-input>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-text-field
                                                            v-model="editedItem.email"
                                                            :rules="emailRules"
                                                            label="E-mail"
                                                            required
                                                            dense
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="isAddItem">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.password"
                                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                        :rules="rules"
                                                        :type="showPassword ? 'text' : 'password'"
                                                        label="Password"
                                                        required
                                                        @click:append="showPassword=!showPassword">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select
                                                            v-model="editedItem.roles"
                                                            :items="roles"
                                                            dense
                                                            label="Rol de usuario"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-checkbox
                                                            v-model="editedItem.active"
                                                            label="Usuario Activo"
                                                            dense
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn :disabled="!valid" color="primary" text @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Está seguro que desea eliminar este Usuario?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog v-model="dialogPassword" max-width="350px" persistent>
                            <v-card>
                                <v-card-title class="text-h6 blue lighten-2">
                                    <span class="headline">Cambiar Contraseña</span>
                                </v-card-title>
                                <v-container class="mt-2">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                v-model="newPassword"
                                                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="rules"
                                                :type="showNewPassword ? 'text' : 'password'"
                                                label="Nueva contraseña"
                                                required
                                                @click:append="showNewPassword=!showNewPassword">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                dense
                                                v-model="confirmPassword"
                                                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                :rules="[rules, passwordConfirmationRule]"
                                                :type="showConfirmPassword ? 'text' : 'password'"
                                                label="Repita la contraseña"
                                                required
                                                @click:append="showConfirmPassword=!showConfirmPassword">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <v-card-actions class="mt-0 pt-0">
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="changePassword()">Aceptar</v-btn>
                                    <v-btn color="blue darken-1" text @click="dialogPassword = false">Cancelar</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>

                        <v-dialog
                            v-model="dialogError"
                            persistent
                            max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error Eliminando Usuario</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-10">{{ messageError }}</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn color="primary" text @click.stop="dialogError = false">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                            v-model="item.active"
                            disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.roles="{ item }">
                    {{ capitalizeFirstLetter(item.roles) }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="openDialogPassword(item)">
                                mdi-key-change
                            </v-icon>
                        </template>
                        <span>Cambiar contraseña</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay Usuarios en el listado.</span>
                    <br/>
                    <v-btn color="primary" @click="getUsers">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
            <v-snackbar
                    v-model="snackBar"
                    :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn  v-bind="attrs"
                            icon
                            @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import userService from '@/providers/UsersService';

    export default {
        name: "AdminUsersComponent",
        data: () => ({
            valid: true,
            snackBar: false,
            snackText: '',
            newPassword: '',
            confirmPassword: '',
            dialogError: false,
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            loadingData: false,
            searchUser: '',
            messageError: '',
            dialog: false,
            dialogDelete: false,
            dialogPassword: false,
            imageFile: null,
            imagePreview: null,
            selectedImageIndex: -1,
            rules: [
                value => !!value || 'La contraseña es obligatoria.',
                value => (value || '').length >= 10 || 'La contraseña debe tener al menos de 10 caracteres.'
            ],
            passwordRules: {
                required: v => !!v || "La contraseña es obligatoria",
                min: v => (v && v.length >= 10) || "La contraseña debe tener al menos de 10 caracteres",
            },
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            imageRules: [
                value =>
                    !value ||
                    value.size < 2000000 ||
                    "El tamaño de la imagen no debe de sobrepasar los 2 MB"
            ],
            users: [],
            roles: ['ADMINISTRADOR', 'INVERSIONISTA'],
            headers: [
                {text: 'Id', value: 'id', align: ' d-none', width: 0}, // para ocultar la columna Id.
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text: 'Apellidos', value: 'last_name'},
                {text: 'E-mail', value: 'email'},
                {text: 'Rol', value: 'roles'},
                {text: 'Activo', value: 'active', align: 'center', width: 100},
                {text: 'Acciones', value: 'actions', width: 110, sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                last_name: '',
                email: '',
                password: null,
                roles: 'INVERSIONISTA',
                active: true,
                pictures: []
            },
            defaultItem: {
                id: 0,
                name: '',
                last_name: '',
                email: '',
                password: null,
                roles: 'INVERSIONISTA',
                active: true,
                pictures: []
            },
            isAddItem: false
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nuevo Usuario' : 'Editar Usuario'
            },
            passwordConfirmationRule() {
                return (this.newPassword === this.confirmPassword) || 'Las contraseñas deben de coincidir.';
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        methods: {
            capitalizeFirstLetter(str) {
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            },
            getUsers() {
                // carga los datos desde el proveedor de datos.
                this.loadingData = true;
                userService.getAllRecords().then(record => {
                    // // console.log('record: ', record.value);
                    this.users = record.value;
                    // this.users = record.data;
                    // console.log("Usuarios: ", this.users);
                    this.loadingData = false;
                    this.isAddItem = false;
                });
            },
            newItem() {
                this.isAddItem = true;
                this.valid = false;
                this.imagePreview = null;
                this.imageFile = null;
                this.selectedImageIndex = -1;
            },
            editItem(item) {
                this.isAddItem = false;
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.imagePreview = null;
                this.selectedImageIndex = -1;
                if (this.editedItem.pictures) {
                    if (this.editedItem.pictures.length > 0) {
                        this.imagePreview = this.editedItem.pictures[0].url;
                        this.selectedImageIndex = this.editedItem.pictures[0].id;
                    }
                }
                this.imageFile = null;
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
                this.isAddItem = false;
            },
            deleteItemConfirm() {
                this.closeDelete();
                this.archiveItemId = this.editedItem;
                // console.log("this.editedItem.id: ", this.editedItem.id);
                userService.deleteRecord(this.editedItem.id).then(
                    response => {
                        // console.log("response: ", response);
                        if (response.success) {
                            this.users.splice(this.editedIndex, 1);
                            this.snackText = 'Usuario eliminado con éxito.';
                            this.snackBar = true;
                            this.getUsers();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                    this.messageError = "Un error Impidió eliminar este Usuario. " +
                        "Esto ocurre debido a que el Usuario está asignado " +
                        "a uno o varios proyectos dentro del sistema.";
                    // console.log("this.archiveItemId: ", this.archiveItemId);
                    // this.archiveItem(this.archiveItemId);
                    this.dialogError = true;
                }).finally(() => {
                    // console.log("result: ", result);
                    // this.closeDelete();
                    // this.snackBar = true;
                });

                /*userService.deleteRecord(this.editedItem.id).then(
                    response => {
                        if (response.success) {
                            this.users.splice(this.editedIndex, 1);
                            this.snackText = 'Usuario eliminado con éxito.';
                        } else {
                            this.snackText = 'Un error impidió eliminar este Usuario';
                        }
                    }
                );
                this.closeDelete();
                this.snackBar = true;*/
            },
            openDialogPassword(item) {
                this.newPassword = '';
                this.confirmPassword = '';
                this.showNewPassword = false;
                this.showConfirmPassword = false;
                this.editedIndex = this.users.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogPassword = true;
            },
            changePassword() {
                this.dialogPassword = false;
                userService.changePassword(this.editedItem.id, this.newPassword).then(
                    response => {
                        if (response.success) {
                            this.snackText = 'Contraseña cambiada con éxito.';
                        } else {
                            this.snackText = 'Un error impidió cambiar la contraseña.';
                        }
                        this.snackBar = true;
                        this.getUsers();
                    }
                );
            },
            close() {
                this.isAddItem = false;
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                    this.imagePreview = null;
                    this.selectedImageIndex = -1;
                })
            },
            closeDelete() {
                this.isAddItem = false;
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                })
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    console.log('editedItem: ', this.editedItem);
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.users[this.editedIndex], this.editedItem);
                        userService.updateRecord(this.editedItem, this.selectedImageIndex, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getUsers();
                                }
                            }
                        );
                    } else {
                        // Agrega el registro por el metodo POST
                        this.users.push(this.editedItem);
                        userService.addRecord(this.editedItem, -1, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getUsers();
                                }
                            }
                        )
                    }
                    this.isAddItem = false;
                    this.close();
                    this.snackText = 'Usuario guardado con éxito.';
                    this.snackBar = true;
                }
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    if (this.editedItem.pictures.length === 0) {
                        let imagePicture = {
                            id: 0,
                            module_id: 1,
                            url: this.imageFile.name
                        };
                        this.editedItem.pictures.push(imagePicture);
                        // console.log('Picture actualizado: ', this.editedItem);
                    }
                    this.snackText = 'Imágen de perfil cargada con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.isAddItem = false;
            this.users = [];
            this.getUsers();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>